import request from "@/utils/request"
import qs from 'qs'

// 查询role
export function getRole(data) {
  return request({
    url: "/role/list",
    method: "get",
    params: data
  })
}

// 新增role
export function addRole(data) {
  return request({
    url: "/role/save",
    method: "post",
    data
  })
}

// 编辑role  
export function updataRole(data) {
  return request({
    url: "/role/update",
    method: "put",
    data
  })
}

// 删除role
export function deleteRole(data) {
  return request({
    url: "/role/deleteById",
    method: "delete",
    params: data
  })
}

// 查询单个角色权限
export function getRolePermissionById(data) {
  return request({
    url: "/menuRight/listByRoleId",
    method: "get",
    params: data
  })
}

// // 修改单个角色权限
// export function updataRolePermissionById(roleId, menuIds) {
//   return request({
//     url: '/menuRight/putMenu?roleId=' + roleId + '&menuIds=' + menuIds,
//     method: "put",
//     // data
//   })
// }

// 修改单个角色权限
export function updataRolePermissionById(data) {
  return request({
    url: '/menuRight/putMenu',
    method: "put",
    params: data,
    paramsSerializer:params=>{
      return qs.stringify(params,{
        indices:false
      })
    }
  })
}

// export const putMenu = (roleId, menuIds) => {
//   return axios({
//     url: '/menuRight/putMenu?roleId=' + roleId + '&menuIds=' + menuIds,
//     method: 'PUT',
//   })
// };
