<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :title="`${permissionName}的栏目权限`"
  >
    <el-tree
      :data="menuData"
      show-checkbox
      node-key="id"
      ref="tree"
      v-loading="dialogLoading"
      :default-checked-keys="menuExpandedKeys"
      :default-expanded-keys="menuExpandedKeys"
      :props="defaultProps"
    >
    </el-tree>
    <!--        :default-expanded-keys="menuExpandedKeys"-->
    <div class="text-right">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureItem">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { obtainMenuList } from "@/api/menuList";
import { getRolePermissionById, updataRolePermissionById } from "@/api/role";
export default {
  data() {
    return {
      dialogVisible: false,
      dialogLoading: false,
      permissionName: null,
      menuData: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      menuCheckedKeys: [],
      menuExpandedKeys: [],
      roleId: null,
    };
  },

  async created() {
    const { data: res } = await obtainMenuList();

    this.menuData = res.data;
  },

  methods: {
    async sureItem() {
      const params = {
        roleId: this.roleId,
        menuIds: this.$refs.tree.getCheckedKeys(),
      };

      const { data: res } = await updataRolePermissionById(params);

      if (res.success) {
        this.$messageSuccess("修改成功");

        this.dialogVisible = false;

        this.$emit("updataRole");
      }
    },

    showDialog(row) {
      this.permissionName = row.cname;

      this.dialogVisible = true;

      this.dialogLoading = true;

      this.roleId = row.id;

      this.getRolePermission();
    },

    async getRolePermission() {
      const { data: res } = await getRolePermissionById({
        roleId: this.roleId,
      });

      if (res.success) {
        this.$refs.tree.setCheckedKeys([]);

        this.menuExpandedKeys = res.data.menuExpandedKeys;

        this.menuCheckedKeys = res.data.menuCheckedKeys;

        this.dialogLoading = false;
      }
    },
  },
};
</script>