<template>
  <div class="role">
    <el-form>
      <el-form-item>
        <el-button
          type="primary"
          @click="
            dialogVisible = true;
            dialogType = 0;
          "
        >
          <i class="el-icon-plus" /> 新增
        </el-button>
      </el-form-item>
    </el-form>

    <el-table
      v-loading="loading"
      :data="roleTable"
      border
      height="652"
      :header-cell-style="cellStyle"
    >
      <el-table-column prop="id" label="ID" align="center" width="100" />
      <el-table-column prop="cname" label="中文名称" align="center" />
      <el-table-column prop="name" label="英文名称" align="center" />
      <el-table-column prop="introduce" label="备注" align="center" />
      <el-table-column fixed="right" label="操作" width="300" align="center">
        <template v-slot="{ row }">
          <div class="operation_bottom">
            <DicButton @buttonClick="viewItem(row)" icon="icon-jr-icon-saw iconfont" text="查看权限" />
            <DicButton @buttonClick="editItem(row)" icon="icon-jr-icon-editt iconfont" text="编辑" />
            <DicButton @buttonClick="deleteItem(row)" icon="icon-jr-icon-delete_1 iconfont" text="删除" />
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      ref="addVisible"
      :title="dialogType ? '编辑角色' : '新增角色'"
      :visible.sync="dialogVisible"
      width="25%"
      @close="handleClose"
    >
      <el-form ref="roleObj" :model="roleObj" :rules="roleRules" label-width="80px">
        <el-form-item label="中文名称" prop="cname">
          <el-input v-model="roleObj.cname" placeholder="请输入中文名称" />
        </el-form-item>
        <el-form-item label="英文名称" prop="name">
          <el-input v-model="roleObj.name" placeholder="请输入英文名称" />
        </el-form-item>
        <el-form-item label="备注" prop="introduce">
          <el-input type="textarea" v-model="roleObj.introduce" placeholder="请输入备注" />
        </el-form-item>
      </el-form>
      <div class="text-right">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>

    <RolePermission ref="rolePermission" @updataRole="updataRole" />

    <el-pagination
      :current-page="queryInfo.pageNumber + 1"
      :page-sizes="[3, 5, 10]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      class="table_page"
    />
  </div>
</template>

<script>
import DicButton from '@/components/DicButton'
import RolePermission from './components/RolePermission'
import { getRole, addRole, updataRole, deleteRole } from '@/api/role'

const defaultRoleObj = Object.freeze({
  cname: null, //中文名称
  name: null, // 英文名称
  introduce: null, // 备注
})
export default {
  name: 'Role',

  components: {
    DicButton,
    RolePermission,
  },

  data() {
    return {
      queryInfo: {
        pageNumber: 0,
        pageSize: 10,
      },
      total: null, // 条数
      dialogType: 0, // 面板类型 0 新增 1 编辑
      roleTable: [],
      roleObj: { ...defaultRoleObj },
      loading: false,
      cellStyle: {
        background: '#EBEAEA',
        color: '#151515',
      },
      dialogVisible: false,
      roleRules: {
        cname: [{ required: true, message: '请输入中文名称', trigger: 'blur' }],
        name: [{ required: true, message: '请输入英文名称', trigger: 'blur' }],
      },
    }
  },

  created() {
    this.search()
  },

  methods: {
    async search() {
      const { data: res } = await getRole(this.queryInfo)

      if (res.data) {
        this.roleTable = res.data

        this.total = res.data.length
      } else {
        this.roleTable = []

        this.total = 0
      }
    },
    // 查看权限
    viewItem(row) {
      this.$refs.rolePermission.showDialog(row)
    },

    // 编辑
    editItem(row) {
      Object.assign(this.roleObj, row)

      this.dialogVisible = true

      this.dialogType = 1
    },

    // 删除
    deleteItem(row) {
      this.$confirm(`确认删除"${row.cname}"吗 ?`)
        .then(async () => {
          const res = await deleteRole({ id: row.id })

          if (res.data.success) {
            this.$messageSuccess('删除成功')

            this.search()
          }
        })
        .catch(() => {})
    },

    handleClose() {
      this.$refs.roleObj.resetFields()

      Object.assign(this.roleObj, defaultRoleObj)

      this.dialogVisible = false
    },

    submitForm() {
      this.$refs.roleObj.validate(async (valid) => {
        if (valid) {
          let res

          if (this.dialogType === 0) {
            res = await addRole(this.roleObj)
          } else {
            res = await updataRole(this.roleObj)
          }

          if (res.data.success) {
            this.$messageSuccess(`角色${this.dialogType ? '编辑' : '新增'}成功`)

            this.search()
          }
          this.dialogVisible = false
        }
      })
    },

    updataRole() {
      this.search()
    },

    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize

      this.search()
    },

    handleCurrentChange(newPage) {
      this.queryInfo.pageNumber = newPage - 1

      this.search()
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.icon-jr-icon-saw{
  color: #4aa9ff;
  font-size: 12px;
}
/deep/.icon-jr-icon-editt{
  color: #7dafff;
  font-size: 12px;
}
/deep/.icon-jr-icon-delete_1{
  color: #ffbda9;
  font-size: 12px;
}
</style>